import Header from './Header/Header';
import Footer from './Footer/Footer';
import { useTheme } from 'next-themes';
const Layout = ({
  children,
  setShowFloatingWindow,
  setShowMainScreen,
  sendMessage,
  setInput,
  input,
  sendMessageLoading
}) => {
  const { theme } = useTheme();

  return (
    <div
      className={`build-up-layout-container`} //${showFloatingWindow ? 'show' : ''}
      style={{ height: "200px", }}
    >
      <Header setShowFloatingWindow={setShowFloatingWindow} setShowMainScreen={setShowMainScreen} />
      <main className="grow flex flex-col w-full">
        {children}
      </main>
      <Footer
        input={input}
        sendMessageLoading={sendMessageLoading}
        sendMessage={sendMessage}
        setInput={setInput}
      />
    </div>
  );
}

export default Layout;
