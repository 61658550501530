import { useState } from 'react';
import darkLogo from '../../Assets/darkLogo.png';
import fireIcon from '../../Assets/fireIcon.png';
import lightLogo from '../../Assets/lightLogo.png';
import { useTheme } from 'next-themes';

import Repat from '../../Assets/main_logo-cropped.svg';

import { Button } from "../../ui/button";
import UPGRADEButton from '../Common/UpgradBtn';
import MenuUpgradebtn from '../Common/MenuUpgradebtn';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../ui/select"
import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarSeparator,
    MenubarSub,
    MenubarSubContent,
    MenubarSubTrigger,
    MenubarTrigger,
} from '../../ui/menubar'
import { Card } from "../../ui/card"
import {
    SquareArrowOutUpRight,
    ChevronRight,
    EllipsisVertical,
    History,
    CopyPlus,
    Brush,
    Languages,
    Settings,
    ArrowLeftRight,
    Sun,
    CloudMoon,
    SquareArrowDown,
    Headset,
    LogOut,
    Flame,
    Zap,
    Info
} from 'lucide-react';
import { ReactComponent as FullScreen } from '../../Assets/icons/full-screen.svg';
import { ReactComponent as SettingsIcon } from '../../Assets/icons/settings.svg';
import { ReactComponent as CloseIcon } from '../../Assets/icons/close.svg';
import './header.scss';

import lighting from '../../Assets/lightning.png'
import { ReactComponent as SettingsMenuIcon } from '../../Assets/icons/set.svg'
import { ReactComponent as SavedMenuIcon } from '../../Assets/icons/saved.svg'

const Header = ({ setShowFloatingWindow, setShowMainScreen }) => {
    const { theme, setTheme } = useTheme();
    const [selectedValue, setSelectedValue] = useState("turbo");
    const [selectedModal, setSelectedModal] = useState("GPT 3.5")

    return (
        <>
            <div className="build-up-header-card">
                <div className="bu-topbar">
                    <img
                        className="build-up-header-logo"
                        src={theme === "dark" ? Repat : Repat}
                        alt="Logo"
                        onClick={() => setShowMainScreen(true)}
                    />
                    {/* <UPGRADEButton /> */}
                    <div className="bu-actions">
                        <Button variant="ghost" size="icon" className="icon-btn">
                            <FullScreen width={32} height={32} />
                        </Button>
                        <Menubar className="build-up-Menu-bar icon-btn">
                            <MenubarMenu>
                                <MenubarTrigger>
                                    <SettingsIcon width={32} height={32} />
                                </MenubarTrigger>
                                <MenubarContent style={{
                                    marginRight: 14,
                                    height: '100%',
                                    paddingBottom: 8,
                                    zIndex: 1000000000,
                                    border: '1px solid rgb(237, 28, 35, 0.3)',
                                }}>
                                    {/* <MenuUpgradebtn /> */}
                                    <MenubarItem className="menubar-item-focused" style={{ marginTop: "2px", marginBottom: "2px" }}>
                                        <History stroke='#777777' width={21} height={21} /> <span style={{ fontSize: "16px", marginTop: "2px", marginRight: "4px" }}> History</span>
                                    </MenubarItem>
                                    <MenubarItem className="menubar-item-focused" style={{ marginTop: "2px", marginBottom: "2px" }}>
                                        {/* <CopyPlus /> */}
                                        <SavedMenuIcon width={21} height={21} />
                                        <span style={{ fontSize: "16px", marginTop: "2px", marginRight: "4px" }}> Saved Prompts</span>
                                    </MenubarItem>
                                    <MenubarSeparator style={{ width: "204px" }} />
                                    <MenubarSub style={{ marginTop: "2px", marginBottom: "2px" }}>
                                        <MenubarSubTrigger className="menubar-item-focused" style={{ marginTop: "2px", marginBottom: "2px" }}>
                                            <Brush stroke='#777777' width={21} height={21} />  <span style={{ fontSize: "16px", marginTop: "2px", marginRight: "4px" }}> Theme</span>
                                        </MenubarSubTrigger>
                                        <MenubarSubContent className="menubar-item-focused-clear" style={{
                                            border: '1px solid rgb(237, 28, 35, 0.3)',
                                        }}>
                                            <MenubarItem className="menubar-item-focused" onClick={() => setTheme('light')}>
                                                <Sun stroke='#777777' style={{ height: "18px" }} /> Light
                                            </MenubarItem>
                                            <MenubarItem className="menubar-item-focused" onClick={() => setTheme('dark')}>
                                                <CloudMoon stroke='#777777' style={{ height: "18px" }} /> Dark
                                            </MenubarItem>
                                            <MenubarItem className="menubar-item-focused" onClick={() => setTheme('system')}>
                                                <SquareArrowDown stroke='#777777' style={{ height: "18px" }} /> System
                                            </MenubarItem>
                                        </MenubarSubContent>
                                    </MenubarSub>
                                    <MenubarItem className="menubar-item-focused" style={{ marginTop: "2px", marginBottom: "2px" }}>
                                        <Languages stroke='#777777' width={21} height={21} /> <span style={{ fontSize: "16px", marginTop: "2px", marginRight: "4px" }}> Language</span>
                                    </MenubarItem>
                                    <MenubarSeparator style={{ width: "204px" }} />
                                    <MenubarItem className="menubar-item-focused" style={{ marginTop: "2px", marginBottom: "2px" }}>
                                        <Settings stroke='#777777' width={21} height={21} /><span style={{ fontSize: "16px", marginTop: "2px", marginRight: "4px" }}> Setting</span>
                                    </MenubarItem>
                                    <MenubarItem className="menubar-item-focused" style={{ marginTop: "2px", marginBottom: "2px", paddingRight: "1px" }}>
                                        {/* <ArrowLeftRight /> */}
                                        <SettingsMenuIcon width={21} height={21} />
                                        <span style={{ fontSize: "16px", marginTop: "2px", marginRight: "4px" }}> Change Shortcuts</span>
                                    </MenubarItem>
                                    <MenubarSeparator style={{ width: "204px" }} />
                                    <MenubarItem className="menubar-item-focused" style={{ marginTop: "2px", marginBottom: "2px" }}>
                                        <Headset stroke='#777777' width={21} height={21} /> <span style={{ fontSize: "16px", marginTop: "2px", marginRight: "4px" }}> Support</span>
                                    </MenubarItem>
                                    <MenubarItem className="menubar-item-focused" style={{ marginTop: "2px" }} onClick={() => {
                                        localStorage.removeItem("bu-auth-token")
                                        setShowFloatingWindow(false);

                                        window?.parent?.postMessage({ type: 'extension-closed' }, '*');
                                    }}>
                                        <LogOut stroke='#777777' width={21} height={21} /> <span style={{ fontSize: "16px", marginTop: "2px", marginRight: "4px" }}> Log Out</span>
                                    </MenubarItem>
                                </MenubarContent>
                            </MenubarMenu>
                        </Menubar>
                        <Button variant="ghost" size="icon" className="icon-btn" onClick={() => {
                            localStorage.setItem('showFloatingScreen', false);
                            const event = new CustomEvent('localStorageChange', {
                                detail: { key: 'showFloatingScreen', value: "false" }
                            });
                            window.dispatchEvent(event);
                            window?.parent?.postMessage({ type: 'extension-closed' }, '*');
                        }} id="bu-close-btn">
                            <CloseIcon width={32} height={32} />
                        </Button>
                    </div>
                </div>
                <div className='build-up-header-selecters'>
                    <Select value={selectedValue} onValueChange={setSelectedValue} style={{backgroundColor: "transparent"}}>
                        <SelectTrigger style={{ height: "36px", width: "255px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            {selectedValue === "turbo" ? (
                                <div className="flex gap-2 items-center w-auto">
                                    <img src={fireIcon} className="w-[21px] h-[21px]" alt="Fire Icon" />
                                    <span style={{ fontSize: "16px", lineHeight: "18px", fontWeight: 600, color: theme == 'dark' ? 'white' : 'black' }}>Access Web: Turbo</span>
                                </div>
                            ) : selectedValue === "regular" ? (
                                <div className="flex gap-2 items-center w-auto">
                                    <img src={fireIcon} className="w-[21px] h-[21px]" alt="Fire Icon" />
                                    <span style={{ fontSize: "16px", lineHeight: "18px", fontWeight: 600, color: theme == 'dark' ? 'white' : 'black' }}>Access Web: Regular</span>
                                </div>
                            ) : (
                                <SelectValue />
                            )}
                        </SelectTrigger>
                        <SelectContent className="build-up-dropdown-selector" style={{ width: "301px", backgroundColor: theme == 'dark' ? "#2a2a2a" : "#FFFFFF", border: "none" }}>
                            <SelectItem value="turbo" style={{ backgroundColor: "rgb(237, 28, 35, 0.3)" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ marginRight: "30px", color: theme == 'dark' ? 'white' : 'black' }}>
                                        <Zap className="w-4 inline-block me-1" fill="#ffe808" strokeWidth={1} />Turbo Mode
                                        <p style={{ marginTop: "5px", fontSize: "9px", fontWeight: 500, color: theme == 'light' ? "#4F4F4F" : "#d6ccc8" }}>OpenAI’s most scalable and fast model.</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={fireIcon} className="w-[21px] h-[18px]" alt="Fire Icon" />
                                        <p style={{ marginTop: "2px", color: theme == 'dark' ? 'white' : 'black'  }}>2x</p>
                                    </div>
                                </div>
                            </SelectItem>
                            <SelectItem value="regular" style={{ backgroundColor: "rgb(237, 28, 35, 0.3)" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ marginRight: "30px", color: theme == 'dark' ? 'white' : 'black' }}>
                                        <Zap className="w-4 inline-block me-1" fill="#ffe808" strokeWidth={1} />Regular Mode
                                        <p style={{ marginTop: "5px", fontSize: "9px", fontWeight: 500, color: theme == 'light' ? "#4F4F4F" : "#d6ccc8" }}>OpenAI’s most scalable and fast model.</p>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={fireIcon} className="w-[21px] h-[18px]" alt="Fire Icon" />
                                        <p style={{ marginTop: "2px", color: theme == 'dark' ? 'white' : 'black'  }}>4x</p>
                                    </div>
                                </div>
                            </SelectItem>
                        </SelectContent>
                    </Select>

                    <Select value={selectedModal} onValueChange={setSelectedModal}>
                        <SelectTrigger style={{ height: "36px", width: "178px", display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src={lighting} />
                                <span style={{ marginLeft: "5px", fontSize: "16px", lineHeight: "18px", fontWeight: 500, color: theme == 'dark' ? 'white' : 'black' }}>  {selectedModal}</span>
                            </div>

                        </SelectTrigger>
                        <SelectContent className="build-up-dropdown-selector" style={{ width: "178px", backgroundColor: theme == 'dark' ? '#2a2a2a': "#FFFFFF", border: "none" }}>
                            <SelectItem style={{ margin: "4px 1px", padding: "1px", paddingLeft: 8, backgroundColor: "rgb(237, 28, 35, 0.3)" }} value="GPT 3.5">
                                <div style={{ display: "flex", alignItems: "center", gap: 8, color: theme == 'dark' ? 'white' : 'black'}}>
                                    <img src={lighting} />
                                    <span> GPT 3.5</span>
                                </div>
                                <p style={{ marginTop: "2px", fontSize: "9px", fontWeight: 500, color: theme == 'light' ? "#4F4F4F" : "#d6ccc8" }}>OpenAI’s most scalable and fast</p>
                            </SelectItem>
                            <SelectItem style={{ margin: "8px 1px", padding: "1px", paddingLeft: 8, backgroundColor: "rgb(237, 28, 35, 0.3)" }} value="GPT 4o Mini">
                                <div style={{ display: "flex", alignItems: "center", color: theme == 'dark' ? 'white' : 'black'}}>
                                    {/* <img src={lighting} /> */}
                                    <span>🌟 GPT 4o Mini</span>
                                </div>
                                <p style={{ marginTop: "2px", fontSize: "9px", fontWeight: 500, color: theme == 'light' ? "#4F4F4F" : "#d6ccc8" }}>OpenAI’s most scalable and fast</p>
                            </SelectItem>
                            <SelectItem style={{ margin: "4px 1px 1px 1px", padding: "1px", paddingLeft: 8, backgroundColor: "rgb(237, 28, 35, 0.3)" }} value="GPT 4o">
                                <div style={{ display: "flex", alignItems: "center", color: theme == 'dark' ? 'white' : 'black'}}>
                                    {/* <img src={lighting} /> */}
                                    <span> 🌟 GPT 4o</span>
                                </div>
                                <p style={{ marginTop: "2px", fontSize: "9px", fontWeight: 500, color: theme == 'light' ? "#4F4F4F" : "#d6ccc8" }}>OpenAI’s most scalable and fast</p>
                            </SelectItem>
                        </SelectContent>
                    </Select>

                </div>
            </div >
        </>
    );
};


export default Header